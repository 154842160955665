import React from 'react'
import { useEffect, useState } from "react";
import load from "./assets/load.png";
const Loading = () => {

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
    }, [])
  return (
    <div>
        {
        loading ? (
        <div className='loading'>
            <img className='turn' src={load} alt="loading.." />
        </div>
        ) : (
            " "
        )
        }
    </div>
  )
}

export default Loading