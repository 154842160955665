import React, { useEffect, useState } from 'react';
import restaurantsData from './restaurants.json';

const Restaurants = () => {
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        setRestaurants(restaurantsData);
    }, []);

    const currentDate = new Date();
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000;

    return (
        <div>
            <div className='container'>
                <div className='item'>
                    <div className='text-pn animation'>
                        <h1 style={{ fontSize: '50px' }}>Fine Dining Experiences in Athens</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                    Athens is a culinary haven, offering Michelin-starred restaurants, fine dining experiences with stunning views of the Parthenon, and gourmet seafood establishments. Whether you crave exquisite French cuisine, authentic Japanese dishes, or innovative fusion flavors, Athens has a restaurant to satisfy every palate. The city's vibrant dining scene ensures that every meal is a memorable feast, combining the best of global gastronomy with the rich culinary heritage of Greece.
                </div>
            </div>
            <div className="museums-list">
                {restaurants.map((restaurant) => {
                    const pastDate = new Date(restaurant.createdAt);
                    const hasThirtyDaysPassed = (currentDate - pastDate) > thirtyDaysInMillis;

                    return (
                        <div key={restaurant.id} className="museum-card">
                            <div className='container'>
                                <div className='item'>
                                    <img className='info-ph' src={restaurant.image} alt="museum" loading="lazy" />
                                </div>
                                <div className='item'>
                                    <div className='text-info'>
                                        <h1 className='animation' style={{ fontSize: '40px' }}>{restaurant.title}</h1>
                                    </div>
                                    <div className='text-info'>
                                        {restaurant.description}
                                    </div>
                                </div>
                            </div>
                            <div className='new'>
                            {!hasThirtyDaysPassed && <p>New Addition!</p>}
                            </div>
                            <div className='btns'>
                                <div className='view-map'>
                                    <a href={restaurant.viewmaps} target='_blank' rel="noreferrer">
                                        <div className='text-info2'>
                                            View in Maps
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='pc-mode'>
                                <p className='scan-txt'>or</p>
                                <div>
                                    <img className='scan-ph' src={restaurant.qr} alt="qr" />
                                </div>
                                <div className='scan-txt'>
                                    Scan to get directions
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Restaurants;
