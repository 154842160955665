import React from 'react';
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <h2 style={{color:'white', fontSize:'50px'}}>404 - Page Not Found</h2>
      <p style={{color:'white', fontSize:'35px'}}>The page you are looking for does not exist.</p>
      <Link to='/' style={{color:'blue'}}>Go back to Main Page </Link>
    </div>
  );
};

export default NotFound;