import React from 'react'
import "./AthensHotspots.css"
import acropolis from '../../assets/theacropolis.webp'
import temple from '../../assets/poseidon.webp'
import niarchos from '../../assets/niarxos.webp'
import lake from '../../assets/lake.webp'
import hill from '../../assets/lyca.webp'
import marina from '../../assets/flisvos.webp'
import { Link } from 'react-router-dom'

const AthensHotspots = () => {
    return (
        <div className='struct-ri'>
            <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 35px' }}>A Journey from Ancient Heritage to Modern Marvels</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                Athens, a city steeped in history and brimming with cultural significance, is a treasure trove of iconic landmarks that captivate the imagination of visitors from around the globe.
                </div>
            </div>
            <div className='rows2'>
                <div className='hotspot'> 
                    <div className='animation2'>
                        <Link to="/hotspots/acropolis" rel="noreferrer">
                            <img className='beach-ph' src={acropolis} alt="acropolis" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    The Acropolis
                    </div>  
                </div>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/hotspots/poseidon_temple" rel="noreferrer">
                        <img className='beach-ph' src={temple} alt="temple" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                   Temple of Poseidon
                    </div>
                </div>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/hotspots/snfcc" rel="noreferrer">
                        <img src={niarchos} className='beach-ph' alt="niarchos" loading='lazy'/> 
                        </Link> 
                    </div>
                    <div className='text-h'>
                    SNFCC
                    </div>
                </div>
                
            </div>
            <div className='rows2'>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/hotspots/lakevouliagmeni" rel="noreferrer">
                        <img src={lake} className='beach-ph' alt="lake" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Lake Vouliagmeni
                    </div>
                </div>
                <div className='hotspot'>
                    <div className='animation2'> 
                        <Link to="/hotspots/lycabettus" rel="noreferrer">
                        <img src={hill} className='beach-ph' alt="hill" loading='lazy' />
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Lycabettus Hill
                    </div>
                </div>
                <div className='hotspot'> 
                    <div className='animation2'>
                        <Link to="/hotspots/flisvos_marina" rel="noreferrer">
                            <img className='beach-ph' src={marina} alt="marina" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Flisvos Marina
                    </div>  
                </div>
            </div>
        </div>
      )
}

export default AthensHotspots