import React from 'react'
import "./Riviera.css"
import { Link } from 'react-router-dom'
import a_plaz from '../../assets/aplaz glyfadas.webp'
import agiosnikolas from '../../assets/agios nikolas.webp'
import agiospetros from '../../assets/agiospetros.webp'
import duni from '../../assets/duni.webp'
import limanakia from '../../assets/limanakia.webp'
import legraina from '../../assets/legraina.webp'
import lobarda from '../../assets/lobarda.webp'
import kavouri from '../../assets/kavouri.webp'
import scales from '../../assets/skales.webp'
import thymari from '../../assets/thymari.webp'


const Unorganized = () => {
    return (
        <div className='struct-ri'>
            <div className='rows2'>
                <div className='riviera'> 
                    <div >
                        <Link to="/unorganized/a_plaz_glyfadas" rel="noreferrer">
                            <img className='beach-ph' src={a_plaz} alt="acropolis-museum" />
                        </Link> 
                    </div>
                    <div className='text'>
                    A' Plaz Glyfadas
                    </div>  
                </div>
                <div className='riviera'>
                    <div>  
                        <Link to="/unorganized/agios_nikolaos" rel="noreferrer">
                        <img className='beach-ph' src={agiosnikolas} alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Agios Nikolaos Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div>  
                        <Link to="/unorganized/agios_petros" rel="noreferrer">
                        <img src={agiospetros} className='beach-ph' alt="andeli-moto" /> 
                        </Link> 
                    </div>
                    <div className='text'>
                    Agios Petros Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div>  
                        <Link to="/unorganized/duni_island" rel="noreferrer">
                        <img src={duni} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Duni Island
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/unorganized/limanakia" rel="noreferrer">
                        <img src={limanakia} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Limanakia Varkizas
                    </div>
                </div>
            </div>
            <div className='rows2'>
                <div className='riviera'>
                    <div> 
                        <Link to="/unorganized/legraina" rel="noreferrer">
                        <img src={legraina} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Legraina
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/unorganized/lobarda" rel="noreferrer">
                        <img src={lobarda} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Lobarda
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/unorganized/kavouri" rel="noreferrer">
                        <img src={kavouri} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Megalo Kavouri
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/unorganized/scales" rel="noreferrer">
                        <img src={scales} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Scales Althea Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/unorganized/thymari" rel="noreferrer">
                        <img src={thymari} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Thymari Beach
                    </div>
                </div>
            </div>
            
        </div>
      )
}

export default Unorganized