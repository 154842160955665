import React from 'react'
import "./Taxi.css"
import uber from '../../assets/uber-2.svg'
import taxiplon from '../../assets/taxiplon.png'

const Taxi = () => {
    return (
        <div className='services'>
          <div className='weather'>
            <div className='services'> 
              <div className='small-btn'>
                  <a href="https://www.taxiplon.gr/en/home-english/" target="_blank" rel="noreferrer" >
                    <img src={taxiplon} alt="taxiplon" />
                  </a> 
              </div> 
            </div>
            <div className='services'>
              <div className='small-btn'>  
                  <a href="https://www.uber.com/gr/en/" target="_blank" rel="noreferrer">
                    <img src={uber} alt="uber" />
                  </a> 
              </div>
            </div>
            
          </div>
        </div>
      )
}

export default Taxi