import React from 'react'
import meteo from '../../assets/meteo.webp'
import poseidon from '../../assets/poseidon-system.webp'
import './Weather.css'

const Weather = () => {
  return (
    <div className='services'>
      <div className='weather'>
        <div className='services'> 
          <div className='small-btn'>
              <a href="https://www.meteo.gr/cf-en.cfm?city_id=12" target="_blank" rel="noreferrer">
                <img src={meteo} alt="weather" />
              </a> 
          </div>
          <div className='text'>
            Meteo.gr <br/> The weather in Greece.
          </div>  
        </div>
        <div className='services'>
          <div className='small-btn'>  
              <a href="https://poseidon.hcmr.gr/" target="_blank" rel="noreferrer">
                <img src={poseidon} alt="weather" />
              </a> 
          </div>
          <div className='text'>
            Poseidon System <br/>
            Monitoring, forecasting <br/>
            and information system <br/>
            for the Greek seas.
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Weather