import React from 'react'
import { Link } from 'react-router-dom'
import "./Riviera.css"
import a_plaz from '../../assets/a_plaz.webp'
import alimos from '../../assets/alimos.webp'
import astir_glyfada from '../../assets/astir_glyfada.webp'
import astir_vouliagmenis from '../../assets/astir_vouliagmenis.webp'
import varkiza from '../../assets/varkiza.webp'
import voula from '../../assets/voula.webp'
import sounio from '../../assets/sounio.webp'
import palia_fokaia from '../../assets/palia_fokaia.webp'
import mavro_lithari from '../../assets/mavro_lithari.webp'


const Organized = () => {
    return (
        <div className='struct-ri'>
            <div className='rows2'>
                <div className='riviera'> 
                    <div >
                        <Link to="/organized/a_plaz_vouliagmenis" rel="noreferrer">
                            <img className='beach-ph' src={a_plaz} alt="acropolis-museum" />
                        </Link> 
                    </div>
                    <div className='text'>
                    A Plaz Vouliagmenis
                    </div>  
                </div>
                <div className='riviera'>
                    <div>  
                        <Link to="/organized/alimos_beach" rel="noreferrer" >
                        <img className='beach-ph' src={alimos} alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Alimos Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div>  
                        <Link to="/organized/astir_glyfadas" rel="noreferrer">
                        <img src={astir_glyfada} className='beach-ph' alt="andeli-moto" /> 
                        </Link> 
                    </div>
                    <div className='text'>
                     Astir Glyfadas
                    </div>
                </div>
                <div className='riviera'>
                    <div>  
                        <Link to="/organized/astir_vouliagmenis" rel="noreferrer">
                        <img src={astir_vouliagmenis} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Astir Vouliagmenis
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/organized/varkiza" rel="noreferrer">
                        <img src={varkiza} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Varkiza Beach
                    </div>
                </div>
            </div>
            <div className='rows2'>
                <div className='riviera'>
                    <div> 
                        <Link to="/organized/sounio" rel="noreferrer">
                        <img src={sounio} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Sounio Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/organized/voula" rel="noreferrer">
                        <img src={voula} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Voula Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/organized/palaia_fokaia" rel="noreferrer">
                        <img src={palia_fokaia} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Palaia Fokaia Beach
                    </div>
                </div>
                <div className='riviera'>
                    <div> 
                        <Link to="/organized/mavro_lithari" rel="noreferrer">
                        <img src={mavro_lithari} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Mavro Lithari Beach
                    </div>
                </div>
            </div>
            
        </div>
      )
}

export default Organized