import React from 'react'
import "./RentVehicle.css"
import hertz from '../../assets/hertz.svg'
import andeli from '../../assets/andeli.png'

const RentVehicle = () => {
  return (
    <div className='services'>
      <div className='weather'>
        <div className='services'> 
          <div className='small-btn'>
              <a href="https://www.hertz.gr/en/?gad_source=1&_gl=1*10efvyn*_up*MQ..&gclid=Cj0KCQjwudexBhDKARIsAI-GWYWQptE_9UU_MG-Hv-5F_RN968G3OUIbHlZWkTfsAP0IM-s7TalJYaIaAuQnEALw_wcB" target="_blank" rel="noreferrer">
                <img src={hertz} alt="hertz-logo" />
              </a> 
          </div>
          <div className='text'>
            Hertz <br/> Car Rental in Greece.
          </div>  
        </div>
        <div className='services'>
          <div className='small-btn'>  
              <a href="https://andelimototouring.gr/en/home/" target="_blank" rel="noreferrer">
                <img src={andeli} alt="andeli-moto" />
              </a> 
          </div>
          <div className='text'>
            Andeli Mototuring<br/>
            Renting - Moto Leasing<br/>
            - Motorcycle Tours.
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default RentVehicle