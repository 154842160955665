import React from 'react'
import './OurPicks.css'
import { Link } from 'react-router-dom'
import taverns from '../../assets/tavern.webp'
import restaurants from '../../assets/restaurants.webp'
import tap from '../../assets/tap.jpg.webp'
import local from '../../assets/local.webp'
import bars from '../../assets/bars.webp'
import cinema from '../../assets/cinema.webp'

const OurPicks = () => {
  return (
    <div className='struct-ri'>
        <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 50px' }}>This is our favorite picks</h1>
                    </div>
            </div>
            <div className='text-pn animation'>
                As the bearer of local knowledge and guardians of our city's secrets, we are delighted to share our curated selection of favorite experiences in Athens. Our recommendations reflect the heart and soul of our vibrant city, ensuring you experience Athens through the eyes of its truest admirers.
            </div>
        </div>
            <div className='rows2'>
                <div className='museums'> 
                    <div className='animation2'>
                        <Link to="/taverns" rel="noreferrer">
                            <img className='beach-ph' src={taverns} alt="acropolis" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Taverns
                    </div>  
                </div>
                <div className='museums'>
                    <div className='animation2'>  
                        <Link to="/restaurants" rel="noreferrer">
                        <img className='beach-ph' src={restaurants} alt="archeologico" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Restaurants
                    </div>
                </div>
                <div className='museums'>
                    <div className='animation2'>  
                        <Link to="/taprooms" rel="noreferrer">
                        <img src={tap} className='beach-ph' alt="gallery" loading="lazy"/> 
                        </Link> 
                    </div>
                    <div className='text-m'>
                     Greek Microbreweries
                    </div>
                </div>
            </div>
            <div className='rows2'>
                <div className='museums'>
                    <div className='animation2'>  
                    <Link to="/athens_neighborhoods" rel="noreferrer">
                        <img src={local} className='beach-ph' alt="benaki" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Athens by Locals
                    </div>
                </div>
                <div className='museums'>
                    <div className='animation2'> 
                        <Link to="/rooftop_bars" rel="noreferrer">
                        <img src={bars} className='beach-ph' alt="istoriko" loading="lazy" />
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Rooftop Bars
                    </div>
                </div>
                <div className='museums'>
                    <div className='animation2'> 
                        <Link to="/summer_cinemas" rel="noreferrer">
                        <img src={cinema} className='beach-ph' alt="cycladic" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Summer Cinema
                    </div>
                </div>
            </div>
            <div className='container' >
                <div className='item'>
                    <div className='text-pn'>
                    Please note that our recommendations are entirely based on our genuine preferences and do not involve any commercial relationships or sponsorships.
                    </div>
                </div>
            </div>
    </div>
  )
}

export default OurPicks