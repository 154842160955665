import React, { useEffect, useState } from 'react';
import neighborhoodsData from './neighborhoods.json';

const AthensNeighborhoods = () => {
    const [neighborhoods, setRestaurants] = useState([]);

    useEffect(() => {
        setRestaurants(neighborhoodsData);
    }, []);
    return (
        <div>
            <div className='container' >
                <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 35px' }}>Athenians Favorite Neighbourhoods</h1>
                </div>
            </div>
                <div className='text-pn animation'>
                Athens presents a captivating blend of the modern and the ancient. The extensive construction of buildings and houses during the 20th century urbanization contributed to its current chaotic structure. Yet, within this bustling cityscape, there are beautiful areas that locals cherish for leisure and enjoyment.
                </div>
            </div>
            <div className="museums-list">
                {neighborhoods.map((neighborhood) => (
                    <div key={neighborhood.id} className="museum-card">
                        <div className='container'>
                            <div className='item'>
                            <img className='info-ph' src={neighborhood.image} alt="museum" loading="lazy"/>
                            </div>
                            <div className='item'>
                                <div className='text-info'>
                                <h1 className='animation' style={{ fontSize: '40px' }}>{neighborhood.title}</h1>
                                </div>
                                <div className='text-info'>
                                {neighborhood.description}
                                </div>
                            </div>
                         </div>
                         <div className='btns'>
                        <div className='view-map'>
                            <a href={neighborhood.viewmaps} target='_blank' rel="noreferrer">
                                <div className='text-info2'> 
                                    View in Maps
                                </div>
                            </a> 
                        </div>
                    </div>
                        <div className='pc-mode'>
                            <p className='scan-txt' >or</p>
                            <div>
                                <img className='scan-ph' src={neighborhood.qr} alt="qr" />
                            </div>
                            <div className='scan-txt'> 
                                Scan to get directions
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AthensNeighborhoods;
