import React from 'react'
import "./Riviera.css"
import { Link } from "react-router-dom";
import acropolis from '../../assets/unorganized.webp'
import archeologico from '../../assets/organized.webp'


const Riviera = () => {
  return (
    <div className='struct-ri'>
        <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 45px' }}>Journeying Along the Riviera's Coastline</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                The Athens Riviera, stretching along the stunning coastline of the Saronic Gulf, <br/> offers a captivating blend of natural beauty, vibrant culture, and luxurious leisure. From the historic port of Piraeus to the picturesque town of Sounion, this coastal paradise beckons travelers with its azure waters, golden beaches, and panoramic vistas. 
                </div>
            </div>
        <div className='rows1'>
            <div className='riviera'> 
                <div className='animation2'>
                    <Link to="/unorganized" rel="noreferrer">
                        <img className='beach-ph' src={acropolis} alt="acropolis-museum" />
                    </Link> 
                </div>
                <div className='text'>
                Unorganized Beaches
                </div>  
            </div>
            <div className='riviera'>
                <div className='animation2'>  
                    <Link to="/organized" rel="noreferrer">
                    <img className='beach-ph' src={archeologico} alt="andeli-moto" />
                    </Link> 
                </div>
                <div className='text'>
                Organized Beaches
                </div>
            </div>
        </div>
    </div>
  )
}

export default Riviera