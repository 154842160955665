import React, { useEffect, useState } from 'react';
import tavernsData from './taverns.json';
import tip from '../../../assets/point.svg';

const Taverns = () => {
    const [taverns, setTaverns] = useState([]);

    useEffect(() => {
        setTaverns(tavernsData);
    }, []);
        
    const currentDate = new Date();
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000;

    return (
        <div>
            <div className='container'>
                <div className='item'>
                    <div className='text-pn animation'>
                        <h1 style={{ fontSize: '50px' }}>Athens Best-Kept Taverna Secrets</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                    Eat like a local at these Athenian tavernas. This is where we go for cheap midweek dinners or long Sunday lunches. There's nothing quite like a meal that brings back the warmth and comfort of your grandmother's kitchen. That's the charm of taverna food—it's hearty, satisfying, and infused with the love and care that only your yiayia could provide.
                </div>
            </div>
            
            <div className="museums-list">
                {taverns.map((tavern) => {
                    // Υπολογισμός ημερομηνιών για την εμφάνιση του "New Addition"
                    const pastDate = new Date(tavern.createdAt);
                    const hasThirtyDaysPassed = (currentDate - pastDate) > thirtyDaysInMillis;

                    return (
                        <div key={tavern.id} className="museum-card">
                            
                            <div className='container'>
                                <div className='item'>
                                    <img className='info-ph' src={tavern.image} alt="museum" loading="lazy" />
                                </div>
                                <div className='item'>
                                    <div className='text-info'>
                                        <h1 className='animation' style={{ fontSize: '40px' }}>{tavern.title}</h1>
                                    </div>
                                    <div className='text-info'>
                                        {tavern.description}
                                    </div>
                                </div>
                            </div>

                             {/* Εμφάνιση του "New Addition" αν δεν έχουν περάσει 30 ημέρες */}
                            <div className='new'>
                                {!hasThirtyDaysPassed && <p>New Addition!</p>}
                            </div>

                            {/* Εμφάνιση ειδικού κειμένου μόνο για αυτό το tavern */}
                            {tavern.id === "Stakalakathoumena" && (
                                <div className='protip text-pn'>
                                    <img className='tip' src={tip} alt="point" loading="lazy" />
                                    <p>We know it's far away from the center of Athens, <br /> but the experience is exceptional. <br /> It is by far the best traditional dishes even for locals!</p>
                                </div>
                            )}

                           

                            <div className='btns'>
                                <div className='view-map'>
                                    <a href={tavern.viewmaps} target='_blank' rel="noreferrer">
                                        <div className='text-info2'>
                                            View in Maps
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='pc-mode'>
                                <p className='scan-txt'>or</p>
                                <div>
                                    <img className='scan-ph' src={tavern.qr} alt="qr" />
                                </div>
                                <div className='scan-txt'>
                                    Scan to get directions
                                </div>
                            </div>
                            
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Taverns;
