import React from 'react'
import { Link } from 'react-router-dom'
import './Shopping.css'
import ermou from '../../assets/ermou.webp'
import voukourestiou from '../../assets/voukourestiou.webp'
import themall from '../../assets/themall.webp'
import metromall from '../../assets/metromall.webp'
import goldenhall from '../../assets/golden.webp'
import citylink from '../../assets/citylink.webp'


const Shopping = () => {
    return (
        <div className='struct-ri'>
        <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 40px' }}>Exploring Athens Retail Diversity</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                Athens boasts a captivating blend of shopping streets and malls, each offering a unique experience for visitors and locals alike. <br/> Whether navigating the historic streets or indulging in contemporary malls, Athens offers a shopping experience that seamlessly blends tradition with modernity.
                </div>
            </div>
            <div className='rows2'>
                <div className='hotspot'> 
                    <div className='animation2'>
                        <Link to="/shopping/ermou_street" rel="noreferrer">
                            <img className='beach-ph' src={ermou} alt="acropolis-museum" />
                        </Link> 
                    </div>
                    <div className='text-s'>
                    Ermou Street
                    </div>  
                </div>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/shopping/voukourestiou_street" rel="noreferrer">
                        <img className='beach-ph' src={voukourestiou} alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text-s'>
                   Voukourestiou Street
                    </div>
                </div>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/shopping/athens_mall" rel="noreferrer">
                        <img src={themall} className='beach-ph' alt="andeli-moto" /> 
                        </Link> 
                    </div>
                    <div className='text-s'>
                    The Mall Athens
                    </div>
                </div>
                
            </div>
            <div className='rows2'>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/shopping/golden_hall" rel="noreferrer">
                        <img src={goldenhall} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text-s'>
                    Golden Hall
                    </div>
                </div>
                <div className='hotspot'>
                    <div className='animation2'> 
                        <Link to="/shopping/athens_metro_mall" rel="noreferrer">
                        <img src={metromall} className='beach-ph' alt="andeli-moto" />
                        </Link> 
                    </div>
                    <div className='text-s'>
                    Athens Metro Mall
                    </div>
                </div>
                <div className='hotspot'> 
                    <div className='animation2'>
                        <Link to="/shopping/citylink" rel="noreferrer">
                            <img className='beach-ph' src={citylink} alt="acropolis-museum" />
                        </Link> 
                    </div>
                    <div className='text-s'>
                    City Link
                    </div>  
                </div>
            </div>
        </div>
    )
}
export default Shopping