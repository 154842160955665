// src/components/Login.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase'; // Import the Firebase auth object
import { encryptData } from './utils/encryption'; // Import encryption utility
import './Login.css'; // Import CSS file for styling

const Login = ({ setAuth }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                const encryptedEmail = encryptData(user.email);

                Cookies.set('authInfo', encryptedEmail, { expires: 7, sameSite: 'Strict' });
                localStorage.setItem('authInfo', encryptedEmail);
                setAuth(true);  // Set authenticated state to true
                navigate('/');  // Redirect to home page
            })
            .catch((error) => {
                setError('Incorrect email or password');
                console.error('Error signing in with password and email', error);
            });
    };

    return (
        <div className="login-container"> {/* Add a container class */}
            <form onSubmit={handleLogin}>
                <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <input type="checkbox" required />
                <label className="lil" htmlFor="agree">
                <p className='terms' onClick={openModal}>I agree to the terms and conditions</p>
                </label>
                <br />
                <button type="submit" className="login-btn">Login</button> {/* Add a button class */}
            </form>
            {error && <p className="error-msg">{error}</p>} {/* Add a class for error message */}
            <div>
            {isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <p>
                        Privacy Policy <br/> <br/>
                        At Info Compass, we take your privacy seriously.
                        This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website [Info Compass](https://infocompass.gr).
                        <br/> <br/>What Information Do We Collect? <br/> <br/>
                        When you visit our website, we may collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the site, and information about how you interact with the site.
                        <br/> <br/>How Do We Use Your Information? <br/> <br/>
                        We use the information we collect to improve and optimize our website (for example, by generating analytics about how our customers browse and interact with the site), to assess the success of our marketing and advertising campaigns, and to personalize your experience on our site. We may also use your information to communicate with you, to screen for potential risk or fraud, and when in line with the preferences you have shared with us, to provide you with information or advertising relating to our products or services.
                        <br/> <br/>Sharing Your Information <br/> <br/>
                        We may share your personal information with third parties to help us use your information, as described above. For example, we use Google Analytics to help us understand how our customers use the site. You can read more about how Google uses your Personal Information here: [Google Privacy Policy](https://www.google.com/intl/en/policies/privacy/). You can also opt-out of Google Analytics here: [Google Analytics Opt-out](https://tools.google.com/dlpage/gaoptout).
                        <br/> <br/>Your Rights <br/> <br/>
                        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                        <br/> <br/>Changes to This Policy <br/> <br/>
                        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                        <br/> <br/>Contact Us <br/> <br/>
                        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at [info@infocompass.gr].
                        </p>
                        </div>
                </div>
            )}
        </div>

        </div>
    );
};

export default Login;
