import React, { useEffect, useState } from 'react';
import taproomsData from './taproom.json';
import './Taprooms.css'

const Taprooms = () => {
    const [taprooms, setTaprooms] = useState([]);

    useEffect(() => {
        setTaprooms(taproomsData);
    }, []);
    return (
        <div>
            <div className='container' >
                <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 50px' }}>Find the Athenian Brews</h1>
                </div>
            </div>
                <div className='text-pn animation'>
                Whether your preference leans towards a crisp lager or pilsner, a vibrant pale ale, a velvety blonde ale, a flavorful red ale, or a robust black ale, or maybe you're drawn to the bold profiles of an IPA or stout – the options abound. And the cherry on top? It's all brewed locally, just around the corner.
                </div>
            </div>
            <div className="museums-list">
                {taprooms.map((taproom) => (
                    <div key={taproom.id} className="museum-card">
                        <div className='container'>
                            <div className='item'>
                            <img className='info-ph' src={taproom.image} alt="museum" loading="lazy"/>
                            </div>
                            <div className='item'>
                                <div className='text-info'>
                                <h1 className='animation' style={{ fontSize: '40px' }}>{taproom.title}</h1>
                                </div>
                                <div className='text-info'>
                                {taproom.description}
                                </div>
                            </div>
                         </div>
                         <div className='btns'>
                        <div className='view-map'>
                            <a href={taproom.viewmaps} target='_blank' rel="noreferrer">
                                <div className='text-info2'> 
                                    View in Maps
                                </div>
                            </a> 
                        </div>
                    </div>
                        <div className='pc-mode'>
                            <p className='scan-txt' >or</p>
                            <div>
                                <img className='scan-ph' src={taproom.qr} alt="qr" />
                            </div>
                            <div className='scan-txt'> 
                                Scan to get directions
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Taprooms;
