import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };
  
  const isRootPath = location.pathname === '/services';
  const isLoginPath = location.pathname === '/login';
  return (
    <div className='header'>
      <div className='nav-menu'>
        {(!isRootPath && !isLoginPath )&& (
          <button className='back-b' onClick={goBack}>Previous <br/>Page</button>
        )}
        <div className='info'>
        <Link to="/services" rel="noreferrer" className='logo'><span id='info'>INFO </span> <br/> <span> COMPASS</span></Link>
        <p className='lil'>INFOFINDER</p>
        </div>
        {(!isRootPath && !isLoginPath ) && (
          <Link className='back-a' to="/" rel="noreferrer">Back <br/> to Main Menu</Link>
        )}
      </div>
      <hr className='line'/>
    </div>
  );
};

export default Header;
