import React, { useEffect, useState } from 'react';
import rooftopData from './rooftop.json';

const RooftopBars = () => {
    const [rooftopbars, setRooftopbars] = useState([]);

    useEffect(() => {
        setRooftopbars(rooftopData);
    }, []);
    return (
        <div>
            <div className='container' >
                <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 50px' }}>Laid-Back Terraces Overlooking the Acropolis</h1>
                </div>
            </div>
                <div className='text-pn animation'>
                The city’s skyline is buzzing with new rooftop bars and terraces popping up everywhere. Whether you're in the mood for a classy night with elegant cocktails and gourmet dining, or you just want to kick back in a relaxed setting, these spots have you covered. And the best part? They all offer stunning views of the Acropolis.
                </div>
            </div>
            <div className="museums-list">
                {rooftopbars.map((rooftopbar) => (
                    <div key={rooftopbar.id} className="museum-card">
                        <div className='container'>
                            <div className='item'>
                            <img className='info-ph' src={rooftopbar.image} alt="museum" loading="lazy"/>
                            </div>
                            <div className='item'>
                                <div className='text-info'>
                                <h1 className='animation' style={{ fontSize: '40px' }}>{rooftopbar.title}</h1>
                                </div>
                                <div className='text-info'>
                                {rooftopbar.description}
                                </div>
                            </div>
                         </div>
                         <div className='btns'>
                        <div className='view-map'>
                            <a href={rooftopbar.viewmaps} target='_blank' rel="noreferrer">
                                <div className='text-info2'> 
                                    View in Maps
                                </div>
                            </a> 
                        </div>
                    </div>
                        <div className='pc-mode'>
                            <p className='scan-txt' >or</p>
                            <div>
                                <img className='scan-ph' src={rooftopbar.qr} alt="qr" />
                            </div>
                            <div className='scan-txt'> 
                                Scan to get directions
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RooftopBars;