import React from 'react';
import "./EmergencyNumbers.css";

const EmergencyNumbers = () => {
  return (
    <div className='useful'>
      <div className='numbers'>
        <span className='txt-big'>Emergency Numbers</span>
        <div className='txt'>
          <p>Emergency 112</p>
          <p>Ambulance 166</p>
          <p>Fire Department 199</p>
          <p>Anti-drug Police 109</p>
          <p>Coast Guard 108</p>
          <p>Tourist Police 171</p>
          <p>Pharmacies 107</p>
          <p>Hospitals 106</p>
          <p>Forest Fire Authority 191</p>
          <p>Traffic Police 10400</p>
          <p>Weather service 148</p>
          <p>International Phone Assistance 139</p>
          <p>General Information 11888</p>
        </div>
      </div>
      <div className='numbers'>
        <span className='txt-big'>Athens Hospitals</span>
        <div className='txt'>
          <p>Evangelismos Hospital tel. +30-210-720-1000</p>
          <p>Alexandras Hospital tel. +30-210-338-1100</p>
          <p>Ippokratio Hospital tel. +30-210-748-3770</p>
          <p>KAT Hospital tel. +30-210-628-0000</p>
          <p>Laiko Hospital tel. +30-210-745-6000</p>
          <p>Areteon Hospital tel. +30-210-728-6000</p>
          <p>Eginitio Hospital tel. +30-210-728-9500</p>
          <p>Geniko Kratiko Hospital tel. +30-210-770-1211</p>
          <p>Thriasio General Hospital tel. +30-210-553-4200</p>
          <p>Sismanoglio General Hospital tel. +30-210-803-9001</p>
          <p>Sotiria Hospital tel. +30-210-777-8611</p>
        </div>
      </div>
      <div className='numbers'>
        <span className='txt-big'>Urban Transportation</span>
        <div className='txt'>
          <p>Attica Metro tel. +30-210-519-4012</p>
          <p>Athens-Piraeus Electric Railways tel. +30-210-324-8311</p>
          <p>Proastiakos (Suburban Railway) tel. +30-210-527-2000</p>
          <p>Hellenic Railways tel. +30-210-529-7777</p>
          <p>Thermal Busses tel. +30-210-493-3002</p>
          <p>Intercity Bus Company tel. 1440</p>
          <p>KTEL Peloponnese tel. +30-210-512-4910</p>
          <p>KTEL Attica tel. 880-8080</p>
        </div>
      </div>
      <div className='numbers'>
        <span className='txt-big'>Maritime Transportation</span>
        <div className='txt'>
          <p>Hellenic Seaways tel. +30-210-419-9000</p>
          <p>Blue Star Ferries tel. +30-210-891-9800</p>
          <p>GA Ferries tel. +30-210-458-2640</p>
          <p>ANEN Lines tel. +30-282-102-0345</p>
          <p>NEL tel. +30-210-411-5015</p>
          <p>ANEK tel. +30-210-419-7420</p>
          <p>Minoan Lines tel. +30-210-414-5700</p>
          <p>Superfast Ferries tel. +30-210-891-9800</p>
        </div>
      </div>
      <div className='numbers'>
        <span className='txt-big'>Air Transportation</span>
        <div className='txt'>
          <p>Eleftherios Venizelos International Airport <br/> tel. +30-210-353-0000</p>
        </div>
      </div>
    </div>
  );
}

export default EmergencyNumbers;
