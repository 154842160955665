import React from 'react'
import './PicnicInAthens.css'
import kaisariani from '../../assets/kaisariani.webp'
import tritsis from '../../assets/tritsis.webp'
import philopappou from '../../assets/philopappou.webp'
import attica from '../../assets/atticapark.webp'
import picnic from '../../assets/palogo.webp'
import syngrou from '../../assets/Alsos-Syngrou.webp'
import { Link } from 'react-router-dom'


const PicnicInAthens = () => {
    return (
        <div className='struct-ri'>
            <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 50px' }}>Picnic in Athens</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                    Even in a bustling city like Athens, you can find beautiful green spaces perfect for a relaxing picnic. Whether in city parks, surrounding forests, or along the Riviera, these spots offer quick escapes just minutes from your hotel or home.
                </div>
            </div>
            <div className='rows2'>
                <div className='hotspot'> 
                    <div className='animation2'>
                        <Link to="/picnic/kaisariani" rel="noreferrer">
                            <img className='beach-ph' src={kaisariani} alt="acropolis-museum" loading="lazy" role='presentation'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Park at the Skopeftirio of Kaisariani
                    </div>  
                </div>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/picnic/tritsis" rel="noreferrer">
                        <img className='beach-ph' src={tritsis} alt="andeli-moto" loading="lazy" role='presentation'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Environmental Park “Antonis Tritsis”
                    </div>
                </div>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/picnic/philopappou" rel="noreferrer">
                        <img src={philopappou} className='beach-ph' alt="philopappou" loading="lazy" role='presentation'/> 
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Philopappou Hill
                    </div>
                </div>
                
            </div>
            <div className='rows2'>
                <div className='hotspot'>
                    <div className='animation2'>  
                        <Link to="/picnic/attica_park" rel="noreferrer">
                        <img src={attica} className='beach-ph' alt="attica" loading="lazy" role='presentation'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Attica Park (Galatsi Area)
                    </div>
                </div>
                <div className='hotspot'>
                    <div className='animation2'> 
                        <Link to="/picnic/syngrou" rel="noreferrer">
                        <img src={syngrou} className='beach-ph' alt="syngrou" loading="lazy" role='presentation'/>
                        </Link> 
                    </div>
                    <div className='text-h'>
                    Syngrou Estate
                    </div>
                </div>
            </div>
            <div className='container'>
                    <div className='item'>
                        <div className='text-pn animation'>
                            “Picnic Athens“ simplifies the process of planning and arranging a picnic.
                            Just select your menu, date and time and allow us to provide you with a delicious, unique and convenient experience.
                        </div>
                    </div>
                    <div className='text-pn animation'>
                            <h2>Greek Gourmet Picnics in a Lunchbox!</h2>
                    </div>
                </div>
            <div className='hotspot'> 
            <div className='text-pn'> Press here to L(a)unch</div>
                     <div className='shake animation2' >
                        <a href="https://picnicathens.gr" target='_blank' rel="noreferrer">
                            <img className='beach-ph' src={picnic} alt="picnic" loading="lazy" role='presentation'/>
                        </a> 
                     </div>
            </div>
        </div>
      )
}

export default PicnicInAthens