import React from 'react'
import './template.css'
import { useParams } from 'react-router-dom';
import data from '../museums.json'; // Adjust the path if necessary

const MuseumsLayout = () => {
  const { id } = useParams();
  const item = data.find((item) => item.id === (id));
  const descriptionWithLineBreaks = item.description.replace(/<br\s*\/?>/g, '\n');
  if (!item) {
    return <div>Item not found</div>;
  }
  return (
    <div>
            <div className='container'>
                <div className='item'>
                    <img className='info-ph' src={item.image} alt="museum" loading="lazy"/>
                </div>
                <div className='item'>
                    <div className='text-info'>
                    {item.title} <br/> <br/>
                    {descriptionWithLineBreaks}
                    </div>
                </div>
            </div>
            <div className='btns'>
                <div className='view-map'>
                    <a href={item.website} target='_blank' rel="noreferrer">
                        <div className='text-info2'> 
                            Official website
                        </div>
                    </a> 
                    <a href={item.viewmaps} target='_blank' rel="noreferrer">
                        <div className='text-info2'> 
                            View in Maps
                        </div>
                    </a> 
                </div>
            </div>
            <div className='pc-mode'>
                <p className='scan-txt' >or</p>
                <div>
                    <img className='scan-ph' src={item.qr} alt="qr" />
                </div>
                <div className='scan-txt'> 
                    Scan to get directions
                </div>
            </div>
        </div>
  )
}

export default MuseumsLayout