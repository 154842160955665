import React from 'react'
import "./Islands.css"
import Saronic from '../../assets/argo2.png'
import Others from '../../assets/ferryhopper-logo-small.svg'
import { Link } from 'react-router-dom'

const Islands = () => {
    return (
        <div className='services'>
            <div className='rows1'>
                <div className='riviera'> 
                    <div>
                        <Link to="/argosaronikos" rel="noreferrer">
                            <img className='beach-ph' src={Saronic} alt="acropolis-museum" />
                        </Link> 
                    </div>
                    <div className='text'>
                    Argosaronic Islands - Near Athens Islands
                    </div>  
                </div>
                <div className='riviera'>
                    <div className='small-btn'>  
                        <a href="https://www.ferryhopper.com/en/#/" target='_blank' rel="noreferrer">
                        <img  src={Others} alt="andeli-moto" />
                        </a> 
                    </div>
                    <div className='text'>
                    FerryHopper - Tickets for all Greek Islands
                    </div>
                </div>
            </div>
        </div>
      )
}

export default Islands