import React, { useEffect, useState } from 'react';
import cinemasData from './cinema.json';
import tip from '../../../assets/point.svg';

const SummerCinemas = () => {
    const [summercinemas, setSummercinemas] = useState([]);

    useEffect(() => {
        setSummercinemas(cinemasData);
    }, []);
    return (
        <div>
            <div className='container' >
                <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 50px' }}>Movies Under the Stars: Open-Air Cinemas with Acropolis Views</h1>
                </div>
            </div>
                <div className='text-pn animation'>
                Cinema under the stars is a beloved Athenian tradition during the hot summer months. Nearly every neighborhood boasts at least one open-air movie theater, with some venues as old as the history of cinema itself.
                </div>
            </div>
            <div className="museums-list">
                {summercinemas.map((summercinema) => (
                    <div key={summercinema.id} className="museum-card">
                        <div className='container'>
                            <div className='item'>
                            <img className='info-ph' src={summercinema.image} alt="museum" loading="lazy"/>
                            </div>
                            <div className='item'>
                                <div className='text-info'>
                                <h1 className='animation' style={{ fontSize: '40px' }}>{summercinema.title}</h1>
                                </div>
                                <div className='text-info'>
                                {summercinema.description} 
                                <br/> <br/>
                                </div>
                            </div>
                         </div>
                         <div className='protip text-pn'>
                         <img className='tip' src={tip} alt="point" loading="lazy"/> 
                         {summercinema.tip}
                         </div>
                         <div className='btns'>
                        <div className='view-map'>
                            <a href={summercinema.viewmaps} target='_blank' rel="noreferrer">
                                <div className='text-info2'> 
                                    View in Maps
                                </div>
                            </a> 
                            <a href={summercinema.movies} target='_blank' rel="noreferrer">
                                <div className='text-info2'> 
                                    See Movies
                                </div>
                            </a>
                        </div>
                    </div>
                        <div className='pc-mode'>
                            <p className='scan-txt' >or</p>
                            <div>
                                <img className='scan-ph' src={summercinema.qr} alt="qr" />
                            </div>
                            <div className='scan-txt'> 
                                Scan to get directions
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SummerCinemas;