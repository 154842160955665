import React from 'react'
import './OutdoorActivities.css'
import { Link } from 'react-router-dom'
import bikemeup from '../../assets/bikemeup.png'
import trekking from '../../assets/trekking-logo.svg'
import voyagers from '../../assets/truevoyagers.webp'
import clio from '../../assets/clio-muse.svg'
import rentboat from '../../assets/rentboat.png'
import yachting from '../../assets/istion.webp'
import meteora from '../../assets/meteora.png'
import kanelakis from '../../assets/kanelakis.webp'
import skydive from '../../assets/skydive.webp'

const OutdoorActivities = () => {
    return (
        <div className='services'>
            <div className='container' >
                <div className='item' >
                    <div className='text-pn animation'>
                        <h1 style={{ fontSize: ' 40px' }}>Athens: The Gateway to Adventure</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                    Athens is not only rich in history and culture but also offers a variety of outdoor activities for nature enthusiasts and adventure seekers. The city is surrounded by stunning landscapes and natural beauty, making it an ideal destination for hiking, biking, and exploring.
                </div>
            </div>
            <div className='weather'>
                <div className='services' id='act'>
                    <div className='small-btn animation2'>
                        <Link to="https://bikemeup.gr/tours" rel="noreferrer" target='_blank'>
                            <img src={bikemeup} alt="bikemeup" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Hybrid Ebike <br /> Rentals & Tours</div>
                    <div className='text-m'>
                        BIKE ME UP is the realization of our idea for the development of an e-bike tour company not only for cycling on the most beautiful places of Greece but also for enjoying all its magical sceneries.
                        When it comes to selecting a tour company, you deserve nothing but the best. These folks go beyond just offering tours; they create unforgettable experiences.
                        With careful planning, unique destinations, and exceptional service, they turn every trip into a cherished memory.
                    </div>
                </div>
                <div className='services' id='act'>
                    <div className='small-btn animation2'>
                        <Link to="https://trekking.gr/en" rel="noreferrer" target='_blank'>
                            <img src={trekking} alt="trekking" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Adventure Tours<br /> and Activities </div>
                    <div className='text-m'>
                        Discover the captivating fusion of ancient history and modern allure in Athens – a city that effortlessly blends the past and the present. 
                        Embrace an array of active and cultural outdoor experiences with our expertly curated tours.
                    </div>
                </div>
                <div className='services' id='act'>
                    <div className='small-btn animation2'>
                        <Link to="https://truevoyagers.com/tours" rel="noreferrer" target='_blank'>
                            <img src={voyagers} alt="voyagers" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Personalized <br /> Alternative Tours </div>
                    <div className='text-m'>
                        Discover urban Athens through unique, personalized activities and essential travel services. Immerse yourself in the city's diverse experiences, 
                        from historic landmarks to vibrant neighborhoods, ensuring a memorable and enriching journey through all facets of Athens.
                    </div>
                </div>
                <div className='services' id='act'>
                    <div className='small-btn animation2' id='act'>
                        <Link to="https://cliomusetours.com/tour-destination/athens/" rel="noreferrer" target='_blank'>
                            <img src={clio} alt="clio" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Self-Guided <br />Audio Tours</div>
                    <div className='text-m'>
                        Explore at your own pace with self-guided audio tours! Discover hidden gems, historical insights, and local stories without a guide. 
                        Download tours for landmarks, museums, or even walking tours - all on your schedule.
                    </div>
                </div>
                <div className="services" id='act'>
                    <div className='small-btn animation2' id='act'>
                        <Link to="https://www.lagonisi-rentaboat.gr/en/" rel="noreferrer" target='_blank'>
                            <img src={rentboat} alt="greekadv" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Athens Riviera <br /> Boat Rental </div>
                    <div className='text-m'>
                        Escape the crowds! Explore hidden coves & beaches on the Athenian Riviera with a private boat rental. 
                        Swim, sunbathe, & create unforgettable memories
                    </div>
                </div>
                <div className="services" id='act'>
                    <div className='small-btn animation2'>
                        <Link to="https://www.istion.com/yacht-charters/day-trips/athens-day-sailing-trips" rel="noreferrer" target='_blank'>
                            <img src={yachting} alt="greekadv" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Athens Riviera <br /> Boat Tours </div>
                    <div className='text-m'>
                        Escape the bustling city and set sail on an Athenian cruise! 
                        Choose between two exciting options: explore the charming island of Aegina in the Saronic Gulf 
                        or discover hidden coves and pristine beaches along the Athenian Riviera. 

                    </div>
                </div>
                <div className="services" id='act'>
                    <div className='small-btn animation2'>
                        <Link to="https://meteora.com/tour/1-day-meteora-tour-athens-train/?gad_source=1&gclid=CjwKCAjw34qzBhBmEiwAOUQcF9gpBkQ_Erfz_BpgeDf1GjpuGYvUY4e1AFvO0WFZvx78ETiGEKQQtxoCAoEQAvD_BwE" rel="noreferrer" target='_blank'>
                            <img src={meteora} alt="greekadv" loading="lazy" />
                        </Link>
                    </div>
                    <div className='text-pn'>Meteora Tour <br /> From Athens</div>
                    <div className='text-m'>
                        Meteora Thrones is the leading Local travel service company in Meteora and the surrounding area, 
                        which has earned the trust and loyalty of visitors who want to organize in the best and most effective way 
                        their visit to Meteora and the Holy Monasteries.
                    </div>
                    <div className="services" id='act'>
                        <div className='small-btn animation2'>
                            <Link to="https://www.kanelakisdiving.com/" rel="noreferrer" target='_blank'>
                                <img src={kanelakis} alt="greekadv" loading="lazy" />
                            </Link>
                        </div>
                        <div className='text-pn'>Kanelankis Diving <br />Experiences</div>
                        <div className='text-m'>
                        Kanelakis Diving Experiences, a PADI 5 Star Dive Center in Nea Makri, offers scuba diving, first dive certifications, and dive trips in Schinias Bay. Guests can explore shipwrecks, caves, and rocky slopes or relax on boat trips to hidden bays. With safety and visitor enjoyment as top priorities, a wonderful time is guaranteed.
                        </div>
                    </div>
                    <div className="services" id='act'>
                        <div className='small-btn animation2'>
                            <Link to="https://skydiveattica.gr/en/" rel="noreferrer" target='_blank'>
                                <img src={skydive} alt="greekadv" loading="lazy" />
                            </Link>
                        </div>
                        <div className='text-pn'>SkyDive Attica </div>
                        <div className='text-m'>
                        Skydive Attica is a Skydiving School and provides the ultimate training and experience to anyone who wants to try or loves skydiving. Skydive Attica is the ultimate destination for experienced skydivers, apprentices, and for those who wish to experience freefall either by jumping with the instructor (ALMA TANDEM) or by flying inside the only in Greece WIND TUNNEL Freefall Simulator, ATTICAFLY. Unique experience for young and old.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OutdoorActivities