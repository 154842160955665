import React from 'react'
import "./Transportation.css"
import metro from '../../assets/stasy.webp'
import oasa from '../../assets/oasa.webp'
import train from '../../assets/train.webp'


const Transportation = () => {
  return (
    <div>
        <div className='services'>
      <div className='transport'>
        <div className='services'> 
          <div className='small-btn'>
              <a href="https://www.stasy.gr/en/" target="_blank" rel="noreferrer">
                <img src={metro} alt="hertz-logo" />
              </a> 
          </div>
          <div className='text'>
            STASY <br/> Athers Urban Railway.
            <br/> Metro and Light Rail (Tram)
          </div>  
        </div>
        <div className='services'>
          <div className='small-btn'>  
              <a href="https://www.hellenictrain.gr/en/ticket-purchase" target="_blank" rel="noreferrer">
                <img src={train} alt="andeli-moto" />
              </a> 
          </div>
          <div className='text'>
            Hellenic Train<br/>
            Athens Suburban and Regional<br/>
             Railway.
          </div>
        </div>
        <div className='services'>
          <div className='small-btn'>  
              <a href="https://www.oasa.gr/en/" target="_blank" rel="noreferrer">
                <img src={oasa} alt="andeli-moto" />
              </a> 
          </div>
          <div className='text'>
            OASA Transport of Athens<br/>
            Tickets for Bus, Metro, Tram <br/> and Trolley.
          </div>
        </div>
        
      </div>
    </div>
    </div>
  )
}

export default Transportation