// src/utils/encryption.js
import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY; // Use an environment variable for your secret key

const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

const decryptData = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export { encryptData, decryptData };
