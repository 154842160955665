import React from 'react'
import './template.css'
import { useParams } from 'react-router-dom';
import data from '../organized.json'; // Adjust the path if necessary

const OrganizedLayout = () => {
  const { id } = useParams();
  const item = data.find((item) => item.id === (id));
  
  if (!item) {
    return <div>Item not found</div>;
  }
  const descriptionWithLineBreaks = item.description.replace(/<br\s*\/?>/g, '\n');
  return (
    <div>
        <div className='container'>
            <div className='item'>
                <img className='info-ph' src={item.image} alt="beach" loading="lazy"/>
            </div>
            <div className='item'>
                <div className='text-info'>
                {item.title} <br/> <br/>
                {descriptionWithLineBreaks}
                </div>
            </div>
        </div>
        <div>
            <div className='view-map'>
                <a href={item.viewmap} target='_blank' rel="noreferrer">
                    <div className='text-info'> 
                        View in Maps
                    </div>
                </a> 
            </div>
        </div>
        <div className='pc-mode'>
            <p className='scan-txt' >or</p>
            <div>
                <img className='scan-ph' src={item.qr} alt="qr_aplaz" />
            </div>
            <div className='scan-txt'> 
                Scan to get directions
            </div>
        </div>
    </div>
    
  )
}

export default OrganizedLayout