import React from 'react'
import "./Argosaronikos.css"
import aegina from '../../assets/aegina.webp'
import agistri from '../../assets/agistri.webp'
import hydra from '../../assets/hydra.webp'
import poros from '../../assets/poros.webp'
import salamina from '../../assets/salamina.webp'
import spetses from '../../assets/spetses.webp'
import { Link } from 'react-router-dom'


const Argosaronikos = () => {
    return (
        <div className='struct-ri'>
            <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 45px' }}>Sailing the Argosaronic Seas</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                The Argosaronic Islands, nestled in the sparkling waters of the Saronic Gulf, offer a blissful escape from the bustle of Athens city.
                </div>
            </div>
            <div className='rows2'>
                <div className='saronikos'> 
                    <div className='animation2'>
                        <Link to="/argosaronikos/aegina" rel="noreferrer">
                            <img className='beach-ph' src={aegina} alt="aegina" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text'>
                    Aegina
                    </div>  
                </div>
                <div className='saronikos'>
                    <div className='animation2'>  
                        <Link to="/argosaronikos/agistri" rel="noreferrer">
                        <img className='beach-ph' src={agistri} alt="agistri" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text'>
                    Agistri
                    </div>
                </div>
                <div className='saronikos'>
                    <div className='animation2'>  
                        <Link to="/argosaronikos/hydra" rel="noreferrer">
                        <img src={hydra} className='beach-ph' alt="hydra" loading='lazy'/> 
                        </Link> 
                    </div>
                    <div className='text'>
                     Hydra
                    </div>
                </div>
                
            </div>
            <div className='rows2'>
                <div className='saronikos'>
                    <div className='animation2'>  
                        <Link to="/argosaronikos/poros" rel="noreferrer">
                        <img src={poros} className='beach-ph' alt="poros" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text'>
                    Poros
                    </div>
                </div>
                <div className='saronikos'>
                    <div className='animation2'> 
                        <Link to="/argosaronikos/salamina" rel="noreferrer">
                        <img src={salamina} className='beach-ph' alt="salamina" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text'>
                    Salamina
                    </div>
                </div>
                <div className='saronikos'> 
                    <div className='animation2'>
                        <Link to="/argosaronikos/spetses" rel="noreferrer">
                            <img className='beach-ph' src={spetses} alt="spetses" loading='lazy'/>
                        </Link> 
                    </div>
                    <div className='text'>
                    Spetses
                    </div>  
                </div>
            </div>
        </div>
      )
}

export default Argosaronikos