import React from 'react'
import "./Museums.css"
import { Link } from 'react-router-dom'
import acropolis from '../../assets/acropolis.webp'
import archeologico from '../../assets/archeologico.webp'
import gallery from '../../assets/gallery.webp'
import benaki from '../../assets/benaki.webp'
import istoriko from '../../assets/istoriko.webp'
import cycladic from '../../assets/cycladic.webp'

const Museums = () => {
    return (
        <div className='struct-ri'>
            <div className='container' >
            <div className='item' >
                    <div className='text-pn animation'>
                    <h1 style={{ fontSize:' 40px' }}>The Museums of Athens: <br/> A Cultural Odyssey</h1>
                    </div>
                </div>
                <div className='text-pn animation'>
                Athens boasts a diverse array of museums that collectively showcase the city's rich historical, cultural, and artistic heritage. 
                </div>
            </div>
            <div className='rows2'>
                <div className='museums'> 
                    <div className='animation2'>
                        <Link to="/museums/acropolis_museum" rel="noreferrer">
                            <img className='beach-ph' src={acropolis} alt="acropolis" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Acropolis Museum
                    </div>  
                </div>
                <div className='museums'>
                    <div className='animation2'>  
                        <Link to="/museums/namuseum" rel="noreferrer">
                        <img className='beach-ph' src={archeologico} alt="archeologico" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    National Archeological Museum
                    </div>
                </div>
                <div className='museums'>
                    <div className='animation2'>  
                        <Link to="/museums/national_gallery" rel="noreferrer">
                        <img src={gallery} className='beach-ph' alt="gallery" loading="lazy"/> 
                        </Link> 
                    </div>
                    <div className='text-m'>
                     National Gallery
                    </div>
                </div>
            </div>
            <div className='rows2'>
                <div className='museums'>
                    <div className='animation2'>  
                    <Link to="/museums/benaki_museum" rel="noreferrer">
                        <img src={benaki} className='beach-ph' alt="benaki" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Benaki Museum
                    </div>
                </div>
                <div className='museums'>
                    <div className='animation2'> 
                        <Link to="/museums/nhmuseum" rel="noreferrer">
                        <img src={istoriko} className='beach-ph' alt="istoriko" loading="lazy" />
                        </Link> 
                    </div>
                    <div className='text-m'>
                    National Historical Museum
                    </div>
                </div>
                <div className='museums'>
                    <div className='animation2'> 
                        <Link to="/museums/cycladic_art" rel="noreferrer">
                        <img src={cycladic} className='beach-ph' alt="cycladic" loading="lazy"/>
                        </Link> 
                    </div>
                    <div className='text-m'>
                    Museum of Cycladic Art
                    </div>
                </div>
            </div>
        </div>
      )
}

export default Museums