import { Link } from "react-router-dom";
import './Services.css'
import weather from '../../assets/weather.webp'
import groceries from '../../assets/groceries-grocery-svgrepo-com.webp'
import transportation from '../../assets/transp.webp'
import delivery from '../../assets/delivery.webp'
import rentcar from '../../assets/rent-a-car-svgrepo-com.webp'
import activities from '../../assets/outdoor.gif'
import important from '../../assets/info.webp'
import shopping from '../../assets/shopping.webp'
import loc from '../../assets/loc.webp'
import special from '../../assets/hot2.webp'
import airport from '../../assets/airport.webp'
import ferry from '../../assets/ferry.webp'
import cash from '../../assets/cash.webp'
import beach from '../../assets/beach.webp'
import map from '../../assets/low.webp'
import safe from '../../assets/safe.webp'
import taxi from '../../assets/taxi.webp'
import picnic from '../../assets/picnic.webp'
import ourpicks from '../../assets/reco.webp'
import laundry from '../../assets/laundry.webp'
import radio from '../../assets/radio.png'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Services = ({ setAuth }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('authInfo');
        localStorage.removeItem('authInfo');
        setAuth(false);  // Set authenticated state to false
        navigate('/login');  // Redirect to login page
    };

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <div>
            <div className='services'>
                {/* COLUMN 1 */}
                <div className='columns'>
                    <div className='large-btn '>
                        <Link to="/activities" rel="noreferrer">
                            <img src={activities} id='activities' alt="activities" />
                            <span>Outdoor Activities</span>
                        </Link>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <a href="https://www.e-food.gr/" target="_blank" rel="noreferrer">
                                <img src={delivery} alt="delivery" />
                                <span>Delivery</span>
                            </a>
                        </div>
                        <div className='small-btn'>
                            <Link to="/rentvehicle" >
                                <img src={rentcar} alt="rentcar" />
                                <span>Rent Vehicle</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <a href="https://www.onexchange.gr/en/branches/" target="_blank" rel="noreferrer">
                                <img src={cash} alt="cash" />
                                <span>Exchange</span>
                            </a>
                        </div>
                        <div className='rows'>
                            <div className='small-btn'>
                                <Link to="/riviera" >
                                    <img src={beach} alt="riviera" />
                                    <span>Riviera</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* COLUMN 2 */}
                <div className='columns'>
                    <div className='rows'>
                        <div className='small-btn'>
                            <a href="https://lockers4all.com.gr/?gclid=CjwKCAjwrvyxBhAbEiwAEg_KgoIcW9Wheo4X_uno8SfVqkX5fvjnOaDaY-AruPf1pi38s93hsiSjZRoCk_EQAvD_BwE" target="_blank" rel="noreferrer">
                                <img src={safe} alt="Storage" />
                                <span>Luggage Storage</span>
                            </a>
                        </div>
                        <div className='small-btn'>
                            <a href="https://allevents.in/athens#search" target='_blank' rel="noreferrer">
                                <img src={map} alt="tours" />
                                <span>Low-Budget Events</span>
                            </a>
                        </div>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <Link to="/islands" >
                                <img src={ferry} alt="ferry" />
                                <span>Greek Islands</span>
                            </Link>
                        </div>
                        <div className='small-btn'>
                            <Link to="/emergency_numbers" rel="noreferrer">
                                <img src={important} alt="important" />
                                <span>Useful Numbers</span>
                            </Link>
                        </div>
                    </div>
                    <div className='large-btn'>
                        <Link to="/our_picks" >
                            <img src={ourpicks} alt="ourpicks" />
                            <span>Our (Hidden Gems) Picks</span>
                        </Link>
                    </div>
                </div>
                {/* COLUMN 3 */}
                <div className='columns'>
                    <div className='rows'>
                        <div className='large-btn'>
                            <Link to="/weather" >
                                <img src={weather} alt="weather" loading='lazy' />
                                <span>Weather</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <Link to="/shopping"  >
                                <img src={shopping} alt="shopping" />
                                <span>Shopping</span>
                            </Link>
                        </div>
                        <div className='small-btn'>
                            <Link to="/taxi" rel="noreferrer">
                                <img src={taxi} alt="taxi" />
                                <span>Taxi</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <Link to="/museums" >
                                <img src={loc} alt="museums" />
                                <span>Museums</span>
                            </Link>
                        </div>
                        <div className='small-btn'>
                            <Link to="/hotspots" >
                                <img src={special} alt="special" />
                                <span>Athens Hotspots</span>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* COLUMN 4 */}
                <div className='columns'>
                    <div className='rows'>
                        <div className='small-btn'>
                            <a href="https://www.aia.gr/traveler/flight-info/rtfi/CachedArrivals" target="_blank" rel="noreferrer">
                                <img src={airport} alt="airport" />
                                <span>Athens Airport</span>
                            </a>
                        </div>
                        <div className='small-btn'>
                            <Link to="/picnic" rel="noreferrer">
                                <img src={picnic} alt="picinic" />
                                <span>Picnic Spots</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <a href="https://www.e-food.gr/delivery/menu/sklavenitis?utm_source=google&utm_medium=cpc&utm_campaign=10208777196&sem_tracker=10208777196&gad_source=1&gclid=CjwKCAjwrvyxBhAbEiwAEg_KgmyFsxuUrBIEDcqwQuQCHzPeY5T4OLHlvNap8Z0dH-KUQFBXvNY_BhoC1rYQAvD_BwE" target="_blank" rel="noreferrer">
                                <img src={groceries} alt="groceries" />
                                <span>Food and Groceries</span>
                            </a>
                        </div>
                        <div className='small-btn'>
                            <Link to="https://selfservicelaundry.gr/en/shop-near-me/" rel="noreferrer" target='_blank'>
                                <img src={laundry} alt="laundry" />
                                <span>Self-Service Laundry</span>
                            </Link>
                        </div>
                    </div>
                    <div className='rows'>
                        <div className='small-btn'>
                            <Link to="/transportation" >
                                <img src={transportation} alt="transportation" />
                                <span>Transportation</span>
                            </Link>
                        </div>
                        <div className='small-btn' >
                            <a href="http://radio.garden/" target="_blank" rel="noreferrer">
                                <img src={radio} alt="radio" />
                                <span>Your hometown radio, worldwide.</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div className='footer'>
                <button className='footer-b' onClick={openModal}>About the Web Application</button>
                <Link to="/contact" className='footer-a'>Report what you didn't find in our App</Link>
            </div>
            <div>
                {isOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={closeModal}>&times;</span>
                            <p>
                        Privacy Policy <br/> <br/>
                        At Info Compass, we take your privacy seriously.
                        This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website [Info Compass](https://infocompass.gr).
                        <br/> <br/> What Information Do We Collect? <br/> <br/>
                        When you visit our website, we may collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the site, and information about how you interact with the site.
                        <br/> <br/> How Do We Use Your Information? <br/> <br/>
                        We use the information we collect to improve and optimize our website (for example, by generating analytics about how our customers browse and interact with the site), to assess the success of our marketing and advertising campaigns, and to personalize your experience on our site. We may also use your information to communicate with you, to screen for potential risk or fraud, and when in line with the preferences you have shared with us, to provide you with information or advertising relating to our products or services.
                        <br/> <br/> Sharing Your Information <br/> <br/>
                        We may share your personal information with third parties to help us use your information, as described above. For example, we use Google Analytics to help us understand how our customers use the site. You can read more about how Google uses your Personal Information here: [Google Privacy Policy](https://www.google.com/intl/en/policies/privacy/). You can also opt-out of Google Analytics here: [Google Analytics Opt-out](https://tools.google.com/dlpage/gaoptout).
                        <br/> <br/> Your Rights <br/> <br/>
                        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                        <br/> <br/> Changes to This Policy <br/> <br/>
                        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                        <br/> <br/> Contact Us <br/> <br/>
                        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at [info@infocompass.gr].
                        </p>
                             </div>
                    </div>
                )}
            </div>
            <button className="logout-btn" onClick={handleLogout}>Logout</button>
        </div>
    )
}

export default Services