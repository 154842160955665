import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

ReactGA.initialize("G-QHBX2NWLFH");
ReactGA.send({ 
    hitType: "pageview", 
    page: window.location.pathname, 
  });

const tagManagerArgs = {
    gtmId: 'GTM-5LVRDVTC' 
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


