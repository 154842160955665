import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Services from './components/Services/Services';
import Weather from './pages/Weather/Weather';
import Riviera from './pages/Riviera/Riviera';
import RentVehicle from './pages/RentVehicle/RentVehicle';
import Transportation from './pages/Transportation/Transportation';
import Museums from './pages/Museums/Museums';
import Organized from './pages/Riviera/Organized';
import Unorganized from './pages/Riviera/Unorganized';
import Argosaronikos from './pages/Argosaronikos/Argosaronikos';
import Shopping from './pages/Shopping/Shopping';
import AthensHotspots from './pages/AthensHotspots/AthensHotspots';
import Taxi from './pages/Taxi/Taxi';
import Islands from './pages/Argosaronikos/Islands';
import EmergencyNumbers from './pages/EmergencyNumbers/EmergencyNumbers';
import Contact from './components/Contact/Contact';
import ScrollToTop from './components/ScrollToTop';
import ShoppingLayout from './pages/Shopping/ShopInfo/ShoppingLayout';
import MuseumsLayout from './pages/Museums/MuseumsInfo/MuseumsLayout';
import HotspotsLayout from './pages/AthensHotspots/HotspotInfo/HotspotsLayout';
import SaronikosLayout from './pages/Argosaronikos/Islands/SaronikosLayout';
import OrganizedLayout from './pages/Riviera/OrganizedInfo/OrganizedLayout';
import UnorganizedLayout from './pages/Riviera/UnorganizedInfo/UnorganizedLayout';
import PicnicInAthens from './pages/PicnicInAthens/PicnicInAthens';
import PicnicLayout from './pages/PicnicInAthens/PicnicLayout';
import OurPicks from './pages/OurPicks/OurPicks';
import NotFound from './components/NotFound';
import OutdoorActivities from './pages/OutdoorActivites/OutdoorActivities';
import Taprooms from './pages/OurPicks/Taprooms/Taprooms';
import Taverns from './pages/OurPicks/Taverns/Taverns';
import Restaurants from './pages/OurPicks/Restaurants/Restaurants';
import AthensNeighborhoods from './pages/OurPicks/AthensNeighborhoods/AthensNeighborhoods';
import RooftopBars from './pages/OurPicks/RooftopBars/RooftopBars';
import SummerCinemas from './pages/OurPicks/SummerCinemas/SummerCinemas';
import Loading from './Loading';
import Cookies from 'js-cookie';
import Login from './Login';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Check if the user is already logged in by checking the cookie or local storage
        const username = Cookies.get('authInfo') || localStorage.getItem('authInfo');
        if (username) {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <div className="App">
            <Router>
                <header className='header'>
                    <Header/>
                </header>
                <ScrollToTop/>
                <Loading/>
                <main>
                    <Routes>
                        <Route path="/login" element={<LoginRoute isAuthenticated={isAuthenticated} setAuth={setIsAuthenticated} />} />
                        <Route path="/services" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Services setAuth={setIsAuthenticated} />} />} />
                        <Route path="/contact" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Contact />} />} />
                        <Route path="/weather" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Weather setAuth={setIsAuthenticated}/>} />} />
                        <Route path="/emergency_numbers" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<EmergencyNumbers />} />} />
                        <Route path="/taxi" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Taxi />} />} />
                        <Route path="/riviera" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Riviera />} />} />
                        <Route path="/rentvehicle" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<RentVehicle />} />} />
                        <Route path="/transportation" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Transportation />} />} />
                        <Route path="/islands" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Islands />} />} />
                        <Route path="/our_picks" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<OurPicks />} />} />
                        <Route path="/taprooms" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Taprooms />} />} />
                        <Route path="/taverns" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Taverns />} />} />
                        <Route path="/restaurants" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Restaurants />} />} />
                        <Route path="/athens_neighborhoods" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<AthensNeighborhoods />} />} />
                        <Route path="/rooftop_bars" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<RooftopBars />} />} />
                        <Route path="/summer_cinemas" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<SummerCinemas />} />} />
                        <Route path="/activities" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<OutdoorActivities />} />} />
                        <Route path="/picnic" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<PicnicInAthens />} />} />
                        <Route path="/picnic/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<PicnicLayout />} />} />
                        <Route path="/museums" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Museums />} />} />
                        <Route path="/museums/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<MuseumsLayout />} />} />
                        <Route path="/shopping" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Shopping />} />} />
                        <Route path="/shopping/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<ShoppingLayout />} />} />
                        <Route path="/hotspots" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<AthensHotspots />} />} />
                        <Route path="/hotspots/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<HotspotsLayout />} />} />
                        <Route path="/organized" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Organized />} />} />
                        <Route path="/organized/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<OrganizedLayout />} />} />
                        <Route path="/unorganized" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Unorganized />} />} />
                        <Route path="/unorganized/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<UnorganizedLayout />} />} />
                        <Route path="/argosaronikos" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Argosaronikos />} />} />
                        <Route path="/argosaronikos/:id" element={<PrivateRoute isAuthenticated={isAuthenticated} component={<SaronikosLayout />} />} />
                        <Route exact path="/" element={<Navigate to={isAuthenticated ? "/services" : "/login"} />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </main>
            </Router>  
        </div>
    );
}

function PrivateRoute({ isAuthenticated, component }) {
    const location = useLocation();
    return isAuthenticated ? (
        component
    ) : (
        <Navigate to="/login" replace state={{ from: location }} />
    );
}

function LoginRoute({ isAuthenticated, setAuth }) {
    const location = useLocation();
    const from = location.state?.from?.pathname || "/services";

    return isAuthenticated ? (
        <Navigate to={from} replace />
    ) : (
        <Login setAuth={setAuth} />
    );
}

export default App;
